<template>
  <div class="abnormal-container">
    <el-row class="header" type="flex" :gutter="8">
      <el-col>
        <el-select
          v-model="inwhite"
          placeholder="是否为白名单"
          @change="inwhiteChange"
        >
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>
      </el-col>
      <el-col class="search-option"
        ><el-input
          v-model="userId"
          class="search-input"
          placeholder="输入用户ID"
          clearable
        ></el-input
      ></el-col>
      <el-col class="search-option"
        ><el-input
          v-model="mobile"
          class="search-input"
          placeholder="输入手机"
          clearable
        ></el-input
      ></el-col>
      <el-col class="search-option"
        ><el-input
          v-model="email"
          class="search-input"
          placeholder="输入邮箱"
          clearable
        ></el-input
      ></el-col>

      <el-col class="search-option">
        <el-button style="margin: 0 8px;" type="primary" @click="handleSearch"
          >查询</el-button
        >
        <el-button style="margin: 0 8px;" type="primary" @click="handleReset"
          >重置</el-button
        ></el-col
      >
      <el-col class="create-option">
        <el-button type="primary" @click="showCreateDialog = true"
          >添加黑名单</el-button
        >
      </el-col>
    </el-row>

    <el-radio-group v-model="listTypes" @change="typeChange">
      <el-radio-button label="全部"></el-radio-button>
      <el-radio-button label="黑名单"></el-radio-button>
      <el-radio-button label="异常嫌疑"></el-radio-button>
    </el-radio-group>

    <el-table :data="abnormalList.items" border stripe>
      <el-table-column
        v-for="col in columns"
        :prop="col.id"
        :key="col.id"
        :label="col.label"
        :width="col.width"
        :formatter="col.formatter"
      ></el-table-column>
      <el-table-column label="操作" width="400" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            @click="
              !scope.row.in_whitelist
                ? handleEdit(scope.row, 'white')
                : handleDelete(scope.row, 'white')
            "
            >{{
              !scope.row.in_whitelist ? `添加白名单` : `移除白名单`
            }}</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button
            size="mini"
            type="text"
            @click="
              scope.row.list_type != 1
                ? handleEdit(scope.row, 'black')
                : handleDelete(scope.row, 'black')
            "
            >{{
              scope.row.list_type != 1 ? `添加黑名单` : `移除黑名单`
            }}</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button size="mini" type="text" @click="handleHistory(scope.row)"
            >查询变更记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="currentPage"
      :page-sizes="[30, 10, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="abnormalList.total"
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    ></el-pagination>

    <el-dialog
      :title="isBlack ? '添加黑名单' : '添加白名单'"
      :visible.sync="showCreateDialog"
      width="30%"
      @close="onClose"
    >
      <el-form ref="form" :model="postForm" label-width="80px">
        <el-form-item label="用户ID">
          <el-input v-model="postForm.user_id"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="postForm.extra"
            type="textarea"
            :rows="4"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="showCreateDialog = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="'查询变更记录'"
      :visible.sync="showHistoryDialog"
      width="50%"
      @close="showHistoryDialog = false"
    >
      <el-table :data="abnormalHistory" border stripe>
        <el-table-column
          v-for="col in historyColumns"
          :prop="col.id"
          :key="col.id"
          :label="col.label"
          :width="col.width"
          :formatter="col.formatter"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  fetchAbnormalList,
  fetchAbnormalInfo,
  postWhiteList,
  deleteWhite,
  postBlackList,
  deleteBlack,
} from "@/api/active";

const TYPE_MAP = {
  全部: [1, 2],
  黑名单: [1],
  异常嫌疑: [2],
};

export default {
  data() {
    return {
      columns: [
        { id: "user_id", label: "用户ID" },
        { id: "mobile", label: "手机号" },
        { id: "email", label: "邮箱" },
        { id: "invited_by", label: "上级ID" },
        {
          id: "list_type",
          label: "异常类型",
          formatter: (row) => {
            switch (row.list_type) {
              case 1:
                return <el-tag type="danger">黑名单</el-tag>;
              case 2:
                return <el-tag type="warning">异常嫌疑</el-tag>;
              default:
                return row.list_type;
            }
          },
        },
        {
          id: "in_whitelist",
          label: "是否白名单",
          formatter: (row) => {
            return row.in_whitelist ? "是" : "否";
          },
        },
        {
          id: "signup_at",
          label: "账号创建时间",
          formatter: (row) => {
            return row.signup_at
              ? dayjs(row.signup_at * 1000).format("YYYY-MM-DD HH:mm:ss")
              : "";
          },
          width: 360,
        },
      ],
      historyColumns: [
        {
          id: "list_type",
          label: "类型",
          formatter: (row) => {
            switch (row.list_type) {
              case 1:
                return <el-tag type="danger">黑名单</el-tag>;
              case 2:
                return <el-tag type="warning">异常嫌疑</el-tag>;
              case 4:
                return <el-tag>白名单</el-tag>;
              default:
                return row.list_type;
            }
          },
        },
        {
          id: "op_type",
          label: "操作",
          formatter: (row) => {
            switch (row.op_type) {
              case 1:
                return <el-tag>添加</el-tag>;
              case 2:
                return <el-tag type="danger">删除</el-tag>;
              default:
                return row.op_type;
            }
          },
        },
        {
          id: "business_type",
          label: "原因",
          formatter: (row) => {
            // 2邀请邮箱异常，3邀请ip异常，4邀请用户数异常，5手动
            switch (row.business_type) {
              case 2:
                return <el-tag>邀请邮箱异常</el-tag>;
              case 3:
                return <el-tag>邀请ip异常</el-tag>;
              case 4:
                return <el-tag>邀请用户数异常</el-tag>;
              case 5:
                return <el-tag>手动</el-tag>;
              default:
                return row.business_type;
            }
          },
        },
        { id: "extra", label: "备注" },
        {
          id: "op_at",
          label: "变更时间",
          formatter: (row) => {
            return row.op_at
              ? dayjs(row.op_at * 1000).format("YYYY-MM-DD HH:mm:ss")
              : "";
          },
          width: 360,
        },
      ],
      postForm: {
        user_id: "",
        extra: "",
      },
      abnormalList: {
        items: [],
        total: 0,
      },
      abnormalHistory: [],
      isBlack: true,
      showCreateDialog: false,
      showHistoryDialog: false,
      currentPage: 1,
      listTypes: "全部",
      pageSize: 30,
      inwhite: "",
      userId: "",
      mobile: "",
      email: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {},
  methods: {
    fetchData() {
      let query = {};
      query.page = this.currentPage;
      query.page_size = this.pageSize;
      query.list_types = TYPE_MAP[this.listTypes];
      if (this.inwhite !== "") {
        query.in_whitelist = this.inwhite;
      }
      query.user_id = this.userId;
      query.mobile = this.mobile;
      query.email = this.email;
      fetchAbnormalList(query).then((data) => {
        let json = data.data.data;
        this.abnormalList.items = json.list;
        this.abnormalList.total = json.total;
      });
    },
    sizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    currentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    inwhiteChange(val) {
      this.inwhite = val;
    },
    typeChange(val) {
      this.listTypes = val;
      this.fetchData();
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSearch() {
      this.currentPage = 1;
      this.pageSize = 30;
      this.fetchData();
    },
    handleReset() {
      this.currentPage = 1;
      this.pageSize = 30;
      this.inwhite = "";
      this.userId = "";
      this.mobile = "";
      this.email = "";
      this.fetchData();
    },
    handleEdit(data, datatype) {
      this.showCreateDialog = true;
      this.isBlack = datatype == "white" ? false : true;
      this.postForm = {
        user_id: data.user_id,
        extra: data.extra,
      };
    },
    handleDelete(data, datatype) {
      if (datatype == "white") {
        this.$confirm("确认从白名单移除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteWhite({ user_id: data.user_id }).then(() => {
            this.$message({
              message: "删除成功",
              duration: 3000,
              type: "success",
            });
            this.fetchData();
          });
        });
      } else {
        this.$confirm("确认从黑名单移除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteBlack({
            user_id: data.user_id,
            business_types: [2, 3, 5],
          }).then(() => {
            this.$message({
              message: "删除成功",
              duration: 3000,
              type: "success",
            });
            this.fetchData();
          });
        });
      }
    },
    handleHistory(data) {
      this.showHistoryDialog = true;
      fetchAbnormalInfo({ user_id: data.user_id }).then((data) => {
        let json = data.data.data;
        this.abnormalHistory = json.list;
      });
    },
    onClose() {
      this.postForm = {};
      this.isBlack = true;
      this.showCreateDialog = false;
    },
    onSubmit() {
      if (this.isBlack) {
        postBlackList(this.postForm).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      } else {
        postWhiteList(this.postForm).then((data) => {
          if (+data.data.code) {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "error",
            });
            this.handleSuccess();
          } else {
            this.$message({
              message: data.data.msg,
              duration: 3000,
              type: "success",
            });
            this.handleSuccess();
          }
        });
      }
    },
    handleSuccess() {
      this.showCreateDialog = false;
      this.postForm = {};
      this.isBlack = true;
      this.fetchData();
    },
  },
};
</script>

<style lang="less" scoped>
.abnormal-container {
  margin: 20px;

  .header {
    margin-bottom: 20px;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    > .label {
      margin-right: 12px;
    }
  }
}
</style>
